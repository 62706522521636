@charset "utf-8";
@import "main";


.description-red {
  background: #f2b7b53d;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-style: italic;
  margin-bottom: 18px;
  padding: 10px;
}

img {
  width: 752px;
}

.vid-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 24px;
  margin-top: 24px;
}

.vid-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
